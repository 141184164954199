<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-01 22:36:56
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-06 23:06:39
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\cataManage\Index.vue
-->
<template>
  <div class="container">
    <div class="options">
      <a-space>
        <a-button type="primary" icon="plus" @click="handleAdd"
          >创建目录</a-button
        >
        <a-button icon="copy" @click="handleCopy">复制目录</a-button>
        <a-button icon="copy" @click="handleCopyAll">一键复制年数据</a-button>
      </a-space>
    </div>
    <a-table
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :defaultExpandAllRows="false"
      :loading="loading"
      v-if="data.length"
    >
      <span slot="type" slot-scope="type">
        {{ typeFormatter(type) }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a
          @click="handleAdd(record)"
          v-if="record.type != 6 && record.type != -3"
          >新增下级</a
        >
        <a-divider
          type="vertical"
          v-if="record.type != 6 && record.type != -3"
        />
        <!-- <a @click="handleEdit(record)">编辑</a>
        <a-divider type="vertical" /> -->
        <a @click="handleDelete(record.id)">删除</a>
      </span>
    </a-table>
    <cata-modal ref="cataModal" @reload="queryList" />
    <cata-list-modal ref="cataListModal" @reload="queryList" />
    <cata-list-year-modal ref="cataListYearModal" @reload="queryList" />
  </div>
</template>
<script>
import CataModal from "./CataModal.vue";
import CataListModal from "./CataListModal.vue";
import CataListYearModal from "./CataListYearModal.vue";
import { queryAllCatalogmenu, deleteCatalogmenu } from "@/api/cata";
export default {
  name: "",
  data() {
    return {
      loading: false,
      data: [],
      columns: [
        {
          title: "目录名称",
          dataIndex: "menuName",
          key: "menuName",
        },
        // {
        //   title: "英文名称",
        //   dataIndex: "menuEnName",
        //   key: "menuEnName",
        // },
        {
          title: "目录类型",
          dataIndex: "type",
          key: "type",
          align: "center",
          scopedSlots: { customRender: "type" },
        },
        // {
        //   title: "父节点",
        //   dataIndex: "parentId",
        //   key: "parentId",
        //   align: "center",
        // },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  components: { CataModal, CataListModal, CataListYearModal },
  created() {
    this.queryList();
  },
  methods: {
    typeFormatter(type) {
      if (type == 0) {
        return "主目录";
      } else if (type == 1) {
        return "年份";
      } else if (type == 2) {
        return "纳税人性质";
      } else if (type == 3) {
        return "指标准则类型";
      } else if (type == 4) {
        return "时间类型";
      } else if (type == 5) {
        return "对应excel";
      } else if (type == 6) {
        return "对应sheet";
      } else if (type == 7) {
        return "表";
      } else if (type == 8) {
        return "风险规则";
      } else if (type == 9) {
        return "对比规则";
      } else if (type == -1) {
        return "公共模板";
      } else if (type == -3) {
        return "经营信息";
      } else if (type == -4) {
        return "Excel模板";
      }
    },
    async queryList() {
      this.loading = true;
      let res = await queryAllCatalogmenu({
        category: 0,
      });
      if (res.status == 200) {
        this.data = res.data;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    handleAdd(record) {
      this.$refs.cataModal.show(2, record);
    },
    handleEdit(record) {
      this.$refs.cataModal.show(3, record);
    },
    handleCopy() {
      if (this.selectedRows.length != 1) {
        return this.$message.warning("请选择一条数据！");
      }
      let id = this.selectedRows[0].id;
      let type = this.selectedRows[0].type;
      if (type == 0) {
        return this.$message.warning("根节点无法复制！");
      }
      if (type == 6) {
        return this.$message.warning("对应sheet无法复制！");
      }
      this.$refs.cataListModal.show(id, type);
    },
    handleCopyAll() {
      if (this.selectedRows.length != 1) {
        return this.$message.warning("请选择一条数据！");
      }
      let id = this.selectedRows[0].id;
      let type = this.selectedRows[0].type;
      if (type != 1) {
        return this.$message.warning("请选择年份节点！");
      }
      this.$refs.cataListYearModal.show(id, type);
    },
    handleDelete(id) {
      const that = this;
      this.$confirm({
        title: "确认删除",
        content: "是否删除当前数据?",
        onOk: async function () {
          let formdata = new FormData();
          formdata.append("ids", id);
          let res = await deleteCatalogmenu(formdata);
          if (res.status == 200) {
            that.$message.success("删除成功！");
            that.queryList();
          } else {
            that.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        },
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  background: #fff;
  min-height: 280px;
}
.options {
  padding-bottom: 16px;
}
</style>
