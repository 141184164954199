<!--
 * @Author: zhangjingqing
 * @Date: 2022-05-12 16:00:13
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-07 19:30:08
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\cataManage\CataListYearModal.vue
-->
<template>
  <a-modal
    title="选择要复制到的目标目录"
    :width="800"
    :visible="visible"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-alert
      style="margin-bottom: 40px"
      message="该功能会将某个年份下所有目录信息、数据表信息、指标信息复制到目标年份下，请谨慎操作！"
      type="warning"
      show-icon
    />
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="目标目录">
        <a-tree-select
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          :replaceFields="{
            children: 'children',
            title: 'menuName',
            key: 'id',
            value: 'id',
          }"
          placeholder="请选择目标目录"
          tree-default-expand-all
          v-decorator="[
            'id',
            { rules: [{ required: true, message: '目录不能为空!' }] },
          ]"
        >
        </a-tree-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { queryAllCatalogmenu, copyAll } from "@/api/cata";
export default {
  name: "",
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this, { name: "form" }),
      treeData: [],
      id: "",
      type: "",
    };
  },
  methods: {
    show(id, type) {
      this.id = id;
      this.type = type;
      this.queryList();
      this.visible = true;
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.$confirm({
            title: "警告",
            content: "是否确认一键复制?",
            okText: "是",
            cancelText: "否",
            onOk: () => {
              this.copyAll(values);
            },
          });
        }
      });
    },
    async copyAll(values) {
      let params = {
        newPId: values.id,
        oldId: this.id,
      };
      let res = await copyAll(params);
      if (res.status == 200) {
        this.$message.success("复制成功！");
        this.$emit("reload");
        this.visible = false;
      }
    },
    handleCancel() {
      this.form.resetFields();
      this.visible = false;
    },
    async queryList() {
      let res = await queryAllCatalogmenu({
        category: 0,
      });
      if (res.status == 200) {
        this.travelTree(res.data);
        this.treeData = res.data;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    travelTree(datas) {
      for (var i in datas) {
        let node = datas[i];
        if (node.type != this.type) {
          node.disabled = true;
        }
        if (node.type > 1) {
          node.style = "display: none";
        }
        if (datas[i].children) {
          this.travelTree(datas[i].children);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
</style>