<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-05 22:13:10
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-22 09:42:04
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\cataManage\CataModal.vue
-->
<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="父节点">
        <a-tree-select
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          :replaceFields="{
            children: 'children',
            title: 'menuName',
            key: 'id',
            value: 'id',
          }"
          placeholder="请选择父节点"
          tree-default-expand-all
          v-decorator="[
            'parentId',
            { rules: [{ required: true, message: '父节点不能为空!' }] },
          ]"
          @select="changeType"
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item label="目录类型">
        <a-select
          v-decorator="[
            'type',
            { rules: [{ required: true, message: '目录类型不能为空!' }] },
          ]"
          placeholder="请选择目录类型"
          @change="form.setFieldsValue({ menuName: '' })"
          :disabled="!selectDisabled"
        >
          <!-- <a-select-option :value="0"> 主目录 </a-select-option> -->
          <a-select-option :value="1" :disabled="selectDisabled">
            年份
          </a-select-option>
          <a-select-option :value="2"> 纳税人性质 </a-select-option>
          <a-select-option :value="3" :disabled="selectDisabled">
            指标准则类型
          </a-select-option>
          <a-select-option :value="4" :disabled="selectDisabled">
            时间类型
          </a-select-option>
          <a-select-option :value="5" :disabled="selectDisabled">
            对应excel
          </a-select-option>
          <a-select-option :value="6" :disabled="selectDisabled">
            对应sheet
          </a-select-option>
          <a-select-option :value="-1"> 公共模板 </a-select-option>
          <!-- <a-select-option :value="-3"> 经营信息 </a-select-option> -->
        </a-select>
      </a-form-item>
      <a-form-item label="目录名称">
        <a-select
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '对应Excel名称不能为空' }] },
          ]"
          placeholder="请选择对应Excel名称"
          v-if="form.getFieldValue('type') == 5"
        >
          <a-select-option value="增值税纳税申报表">
            增值税纳税申报表
          </a-select-option>
          <a-select-option value="财务报表"> 财务报表 </a-select-option>
          <a-select-option value="企业所得税月（季）度预缴纳税申报表">
            企业所得税月（季）度预缴纳税申报表
          </a-select-option>
          <a-select-option value="企业所得税年度纳税申报表">
            企业所得税年度纳税申报表
          </a-select-option>
        </a-select>
        <a-select
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '纳税人类型不能为空' }] },
          ]"
          placeholder="请选择纳税人性质"
          v-else-if="form.getFieldValue('type') == 2"
        >
          <a-select-option value="一般纳税人"> 一般纳税人 </a-select-option>
          <a-select-option value="小规模纳税人"> 小规模纳税人 </a-select-option>
        </a-select>
        <a-select
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '指标准则类型不能为空' }] },
          ]"
          placeholder="请选择指标准则类型"
          v-else-if="form.getFieldValue('type') == 3"
        >
          <a-select-option value="企业会计准则（2007）">
            企业会计准则（2007）
          </a-select-option>
          <a-select-option value="企业会计准则（2019）">
            企业会计准则（2019）
          </a-select-option>
          <a-select-option value="小企业会计准则（2013）">
            小企业会计准则（2013）
          </a-select-option>
        </a-select>
        <a-select
          v-decorator="[
            'menuName',
            { rules: [{ required: true, message: '时间类型不能为空' }] },
          ]"
          placeholder="请选择时间类型"
          v-else-if="form.getFieldValue('type') == 4"
        >
          <a-select-option value="年"> 年 </a-select-option>
          <a-select-option value="季"> 季 </a-select-option>
          <a-select-option value="月"> 月 </a-select-option>
        </a-select>
        <a-input
          v-decorator="[
            'menuName',
            {
              rules: [
                {
                  required: true,
                  message:
                    form.getFieldValue('type') == 1
                      ? '年份不能为空'
                      : form.getFieldValue('type') == 6
                      ? '对应sheet名称不能为空'
                      : '目录名称不能为空',
                },
              ],
            },
          ]"
          :placeholder="
            form.getFieldValue('type') == 1
              ? '请输入年份'
              : form.getFieldValue('type') == 6
              ? '请输入对应sheet名称'
              : '请输入目录名称'
          "
          v-else
        />
      </a-form-item>
      <!-- <a-form-item label="英文名称">
        <a-input
          v-decorator="[
            'menuEnName',
            { rules: [{ required: true, message: '英文名称不能为空' }] },
          ]"
          placeholder="请输入英文名称"
        />
      </a-form-item> -->
    </a-form>
  </a-modal>
</template>
<script>
import pick from "lodash.pick";
import {
  queryAllCatalogmenu,
  insertCatalogmenu,
  updateCatalogmenu,
} from "@/api/cata";
export default {
  name: "positionModal",
  data() {
    return {
      status: 1,
      visible: false,
      record: {},
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
      treeData: [],
      selectDisabled: false,
    };
  },
  methods: {
    async show(status, record) {
      await this.queryList();
      this.visible = true;
      this.status = status;
      if (record.id) {
        this.record = record;
        if (status == 2) {
          this.$nextTick(() => {
            if (record.type == 1) {
              this.selectDisabled = true;
            } else {
              this.selectDisabled = false;
            }
            let type = record.type + 1;
            if (type == 0) {
              type = 4;
            }
            this.form.setFieldsValue({
              parentId: record.id,
              type: type,
            });
          });
        } else {
          this.$nextTick(() => {
            this.form.setFieldsValue(
              pick(record, "menuName", "menuEnName", "type", "parentId")
            );
          });
        }
      }
    },
    async queryList() {
      let res = await queryAllCatalogmenu({
        category: 0,
      });
      if (res.status == 200) {
        this.travelTree(res.data);
        this.treeData = res.data;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    handleCancel() {
      this.selectDisabled = false;
      this.form.resetFields();
      this.visible = false;
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let res;
          if (this.status == 2) {
            res = await insertCatalogmenu(Object.assign(values));
          } else {
            res = await updateCatalogmenu(
              Object.assign(this.record, values, { category: 0 })
            );
          }
          if (res.status == 200) {
            this.$message.success(
              this.status == 2 ? "新增成功！" : "修改成功！"
            );
            this.visible = false;
            this.form.resetFields();
            this.$emit("reload");
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    },
    changeType(val, node) {
      let type = node.dataRef.type;
      let level = type + 1;
      if (level == 0) {
        level = 4;
      }
      if (type == 1) {
        this.selectDisabled = true;
      } else {
        this.selectDisabled = false;
      }
      this.form.setFieldsValue({ type: level, menuName: undefined });
    },
    travelTree(datas) {
      for (var i in datas) {
        let node = datas[i];
        if (node.type == 6 || node.type == -3) {
          node.disabled = true;
        }
        if (datas[i].children) {
          this.travelTree(datas[i].children);
        }
      }
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 1:
          title = "查看";
          break;
        case 2:
          title = "创建目录";
          break;
        case 3:
          title = "编辑目录";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped></style>
