import { render, staticRenderFns } from "./CataListModal.vue?vue&type=template&id=bd83bd50&scoped=true&"
import script from "./CataListModal.vue?vue&type=script&lang=js&"
export * from "./CataListModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd83bd50",
  null
  
)

export default component.exports